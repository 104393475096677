<template>
    <div class="ftb_details_report_content">
        <div>
            <div itemscope="" itemtype="https://schema.org/NewsArticle" id="preview_b" class="preview_block" v-if="report">
                <h6>
                    <a href="javascript:;">title</a>
                </h6>
                <div itemprop="articleBody" class="ftb_details_report_inner">
                    <div class="block_img">
                        <img itemprop="image" :alt="'title'" class="img-responsive lazy-preview-image" :src="'image_url'" style="">
                    </div>
                    <div class="ftb_details_report_desc" :innerHtml="'body'">
                    </div>
                </div>
            </div>
            <div v-if="!report">
                <p style="padding: 10px; text-align: center;font-size: x-large;font-weight: 500;">
                    {{ $t("No report data") }} ...!!!
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'MatchDetailReport',
    data() {
        return{

        }
    },
}

</script>
